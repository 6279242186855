<template>
  <div class="report pa-4">
    <vLoading v-if="!vLoading" />
    <v-card class="pe-6 ps-6 py-10 common-card">
      <v-row>
        <v-col class="text-center blue-grey--text text--darken-2 pt-sm-0 pt-6"
          ><h5>گزارش پرستار</h5>
        </v-col>
      </v-row>
      <hr class="my-4" />
      <div class="d-md-block d-sm-none">
        <v-stepper v-model="stepNumber" alt-labels>
          <v-stepper-header>
            <v-stepper-step
              :complete="stepNumber > 1"
              :active-icon="'mdi-pen'"
              step="1"
            >
              <div @click="stepNumber = 1" class="text-center pointer">
                <p class="mb-3">گام اول</p>
                <p class="mb-0">انتخاب نوع گزارش</p>
                <div v-if="stepNumber > 1" class="c-grey mb-0 mt-3">
                  <span class="common-text" v-if="selectedReportType">
                    ({{ faSelectedReportType }})
                  </span>
                </div>
              </div>
            </v-stepper-step>
            <v-divider :class="stepNumber > 1 ? 'completed' : ''"></v-divider>
            <v-stepper-step
              :complete="stepNumber > 2"
              :active-icon="'mdi-pen'"
              step="2"
            >
              <div @click="stepNumber = 2" class="text-center pointer">
                <p class="mb-3">گام دوم</p>
                <p class="mb-0">انتخاب تخصص پرستار</p>
                <div v-if="stepNumber > 2" class="c-grey mb-0 mt-3">
                  <span class="common-text" v-if="selesctedNurseExperties">
                    ({{ selesctedNurseExpertiesFa }})
                  </span>
                </div>
              </div>
            </v-stepper-step>
            <v-divider :class="stepNumber > 2 ? 'completed' : ''"></v-divider>

            <v-stepper-step
              :complete="stepNumber > 3"
              :active-icon="'mdi-pen'"
              step="3"
            >
              <div @click="stepNumber = 3" class="text-center pointer">
                <p class="mb-3">گام سوم</p>
                <p class="mb-0">انتخاب پرستار</p>
                <div v-if="stepNumber > 3" class="c-grey mb-0 mt-3">
                  <span class="common-text" v-if="selectedNurses.length">
                    ({{ selectedNurses.length + " پرستار" }})
                  </span>
                </div>
              </div>
            </v-stepper-step>
            <v-divider :class="stepNumber > 3 ? 'completed' : ''"></v-divider>

            <v-stepper-step :complete="stepNumber > 4" step="4">
              <div
                @click="
                  stepNumber > 4 && selectedNurses.length
                    ? (stepNumber = 4)
                    : ''
                "
                :class="
                  stepNumber > 4 && selectedNurses.length ? 'pointer' : ''
                "
                class="text-center"
              >
                <p class="mb-3">گام چهارم</p>
                <p class="mb-0">انتخاب زمان</p>
                <div v-if="stepNumber > 4" class="c-grey mb-0 mt-3">
                  <span class="common-text" v-if="timeType == 'all'"
                    >(گزارش تجمیعی)</span
                  >
                  <span class="common-text" v-if="timeType == 'year'"
                    >(گزارش سال {{ selectedYear }})</span
                  >
                  <div class="common-text" v-if="timeType == 'time'">
                    ( گزارش از تاریخ
                    {{ dateFrom }}
                    تا
                    {{ dateTo }})
                  </div>
                </div>
              </div>
            </v-stepper-step>

            <v-divider :class="stepNumber > 5 ? 'completed' : ''"></v-divider>

            <v-stepper-step step="5">
              <div class="text-center">
                <p class="mb-3">گام پنجم</p>
                <p class="mb-0">مشاهده و خروجی</p>
              </div>
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </div>
      <hr class="my-4" />
      <!-- Step 1 -->
      <div v-if="stepNumber == 1">
        <h5 class="blue-grey--text text--darken-2 text-right">
          <v-icon class="me-1 primary--text">fa fa-pie-chart</v-icon>
          چه گزارشی میخواهید؟
        </h5>
        <v-row class="mt-4 ps-2">
          <v-col
            md="6"
            cols="12"
            v-for="(report, enName) in reportTypes"
            :key="enName"
          >
            <div
              @click="selectReportType(report)"
              class="text-center common-text pa-6 rounded-lg pointer"
              :class="
                selectedReportType == report.enName
                  ? 'active-item-div'
                  : 'item-box'
              "
            >
              {{ report.name }}
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- Step 2 -->
      <div v-if="stepNumber == 2">
        <h5 class="blue-grey--text text--darken-2 text-right">
          <v-icon class="me-1 primary--text">fa fa-pie-chart</v-icon>
          حوزه فعالیت پرستاران مدنظر خود را انتخاب نمایید
        </h5>
        <v-row class="mt-4 ps-2">
          <v-col
            md="6"
            cols="12"
            v-for="(expert, enName) in nurseExperties"
            :key="enName"
          >
            <div
              @click="
                (selesctedNurseExperties = expert.enName),
                  (selesctedNurseExpertiesFa = expert.name),
                  getAllNurses()
              "
              class="text-center common-text pa-6 rounded-lg pointer"
              :class="
                selesctedNurseExperties == expert.enName
                  ? 'active-item-div'
                  : 'item-box'
              "
            >
              {{ expert.name }}
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- Step 3 -->
      <div v-if="stepNumber == 3">
        <div v-if="pageLoading" class="text-center py-16 my-16">
          <fold></fold>
          <p class="blue-grey--text text--darken-2">در حال بارگذاری...</p>
        </div>
        <div v-else>
          <v-row>
            <v-col class="pt-6 text-sm-right text-center">
              <span class="common-text blue-grey--text text--darken-2"
                >پرستار مورد نظر خود را انتخاب کنید.</span
              >
            </v-col>
            <v-col sm="6" cols="12">
              <v-text-field
                rounded
                filled
                clearable
                class="search-box mt-2 mt-sm-0"
                placeholder="نام پرستار را جستجو کنید..."
                v-model="filter"
              >
                <template v-slot:prepend-inner>
                  <v-icon class="me-1 ms-2">fa fa-search</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <div v-if="!this.filter" class="mt-1 mb-5">
            <v-btn
              @click="!total ? selectAll() : removeAll()"
              elevation="0"
              class="all-btn"
              >{{ !total ? "همه" : "هیچ" }}</v-btn
            >
          </div>

          <div
            v-if="!computedNurses.length"
            class="text-center common-text c-grey"
          >
            <hr />
            موردی یافت نشد.
            <hr />
          </div>

          <v-row>
            <v-col
              lg="3"
              md="3"
              sm="4"
              cols="12"
              v-for="(item, index) in computedNurses"
              :key="index"
            >
              <div
                class="item-box common-text d-flex flex-column"
                @click="chooseNurse(item)"
                :class="
                  selectedNursesIds.includes(item.id) ? 'active-item-div' : ''
                "
              >
                <h6>{{ item.name }}</h6>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- Step 4 -->
      <div v-if="stepNumber == 4">
        <v-row>
          <v-col class="pt-6 text-sm-right text-center">
            <span class="common-text blue-grey--text text--darken-2"
              >زمان مورد نظر برای گرفتن گزارش را انتخاب کنید.</span
            >
          </v-col>
        </v-row>
        <v-row class="py-8">
          <v-col md="4" sm="12" cols="12">
            <div
              @click="timeType = 'all'"
              :class="timeType == 'all' ? 'selected-box2' : ''"
              class="common-card h-100 pb-9 b-primary-bold pointer"
            >
              <div class="text-center my-10">
                <p class="primary--text font-weight-bold">تجمیعی</p>
                <p class="c-grey mb-0 mt-14">
                  با انتخاب این گزینه شما قادر به دریافت گزارش تجمیعی هستید.
                </p>
              </div>
            </div>
          </v-col>
          <v-col md="4" sm="12" cols="12">
            <div
              @click="timeType != 'year' ? (showYearModal = true) : ''"
              :class="timeType == 'year' ? 'selected-box2' : 'pointer'"
              class="common-card h-100 pb-9 b-primary-bold"
            >
              <div class="text-center mt-10">
                <p class="primary--text font-weight-bold">تفکیک سال</p>
                <p v-if="timeType != 'year'" class="c-grey mb-0 mt-14 m-10">
                  پس از انتخاب این گزینه و مشخص کردن سال مورد نظر به گام بعدی
                  بروید.
                </p>
                <div v-else class="pt-5">
                  <p class="mb-10">گزارش سال {{ selectedYear }}</p>
                  <v-btn
                    @click="showYearModal = true"
                    elevation="0"
                    class="btn-radius white primary--text w-100"
                    height="44"
                    ><span class="common-text font-weight-bold"
                      >تغییر سال</span
                    ></v-btn
                  >
                </div>
              </div>
            </div>
            <year-modal
              v-if="showYearModal"
              :chosenYear="year"
              @dialogYear="getItemYear"
              @showModal="getShowModal"
            ></year-modal>
          </v-col>
          <v-col md="4" sm="12" cols="12">
            <div
              @click="timeType != 'time' ? goToSelectRange() : ''"
              :class="timeType == 'time' ? 'selected-box2' : 'pointer'"
              class="common-card h-100 pb-9 b-primary-bold"
            >
              <div class="text-center mt-10">
                <p class="primary--text font-weight-bold">بازه زمانی دلخواه</p>
                <p v-if="timeType != 'time'" class="c-grey mb-0 mt-14">
                  پس از انتخاب این گزینه و مشخص کردن بازه زمانی مورد نظر به گام
                  بعدی بروید.
                </p>
                <div v-else class="pt-5">
                  <p class="mb-10">
                    گزارش از تاریخ
                    {{ dateFrom }}
                    تا
                    {{ dateTo }}
                  </p>
                  <v-btn
                    @click="selectTimeRangeDialog = true"
                    elevation="0"
                    class="btn-radius white primary--text w-100"
                    height="44"
                    ><span class="common-text font-weight-bold"
                      >تغییر بازه زمانی</span
                    ></v-btn
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-dialog v-model="selectTimeRangeDialog" width="800">
          <v-card
            class="modal-card"
            style="max-height: 500px; overflow-y: auto"
          >
            <v-container class="pa-5">
              <v-row class="mb-1">
                <v-col class="text-right">
                  <span class="common-text blue-grey--text text--darken-2 pt-1"
                    >بازه زمانی مورد نظر را انتخاب کنید:</span
                  >
                </v-col>
                <v-col cols="2" class="text-left">
                  <span
                    class="common-text"
                    @click="selectTimeRangeDialog = false"
                  >
                    <v-icon class="pointer">$Close</v-icon>
                  </span>
                </v-col>
              </v-row>
              <div class="py-4">
                <v-row>
                  <v-col>
                    <span class="blue-grey--text text--darken-2 pt-3 pb-4"
                      >از تاریخ:</span
                    >
                    <span id="dateFrom">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="dateFrom"
                        :editable="true"
                        class="date-input mt-2"
                        hide-details
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="dateFrom"
                      element="dateFrom"
                      color="#00a7b7"
                    />
                  </v-col>
                  <v-col>
                    <span class="blue-grey--text text--darken-2 pt-3 pb-4"
                      >تا تاریخ:</span
                    >
                    <span id="dateTo">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="dateTo"
                        :editable="true"
                        class="date-input mt-2"
                        hide-details
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="dateTo"
                      element="dateTo"
                      color="#00a7b7"
                      :min="dateFrom"
                    />
                  </v-col>
                </v-row>
                <h6 class="primary--text mt-5 text-right">
                  مدت زمان تولید گزارش:
                  <span class="blue-grey--text text--darken-2">
                    {{ computedNumOfDays }}
                    <span v-if="this.daysDifference > 0">روز</span>
                  </span>
                </h6>
              </div>
              <div class="pt-4 text-right">
                <v-btn
                  class="primary-btn"
                  width="100"
                  height="47"
                  @click="
                    selectTimeRangeDialog = false;
                    timeType = 'time';
                  "
                  >تایید</v-btn
                >
              </div>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog v-model="selectDayModal" width="300">
          <v-card class="modal-card pa-0">
            <div v-if="dateType == 'from'">
              <v-card-title class="primary py-4">
                <span
                  class="mx-auto c-light text-center"
                  style="font-size: 2rem"
                  >{{ range.fromDay }}</span
                >
              </v-card-title>
              <div class="fit-content pa-5">
                <div
                  v-for="(number, index) in fromNumberOfDay"
                  :key="index"
                  class="day-box d-inline-block pointer"
                  :class="parseInt(range.fromDay) == number ? 'selected' : ''"
                  @click="
                    selectDayModal = false;
                    range.fromDay = number;
                  "
                >
                  {{ number }}
                </div>
              </div>
            </div>
            <div v-else>
              <v-card-title class="primary py-4">
                <span
                  class="mx-auto c-light text-center"
                  style="font-size: 2rem"
                  >{{ range.toDay }}</span
                >
              </v-card-title>
              <div class="fit-content pa-5">
                <div
                  v-for="(number, index) in toNumberOfDay"
                  :key="index"
                  class="day-box d-inline-block"
                  :class="parseInt(range.toDay) == number ? 'selected' : ''"
                >
                  <div
                    v-if="
                      range.fromYear == range.toYear &&
                      range.fromMonth == range.toMonth
                    "
                  >
                    <div
                      class="pointer"
                      @click="
                        selectDayModal = false;
                        range.toDay = number;
                      "
                      v-if="index >= parseInt(range.fromDay) - 1"
                    >
                      {{ number }}
                    </div>
                    <div v-else class="disabled">
                      {{ number }}
                    </div>
                  </div>
                  <div
                    class="pointer"
                    @click="
                      selectDayModal = false;
                      range.toDay = number;
                    "
                    v-else
                  >
                    {{ number }}
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
      <!-- Step 5 -->
      <div v-if="stepNumber == 5" class="pb-6">
        <div v-if="pageLoading" class="text-center py-16 my-16">
          <fold></fold>
          <p class="blue-grey--text text--darken-2">در حال بارگذاری...</p>
        </div>
        <div v-else>
          <v-row>
            <v-col v-if="selectedNurses.length" class="pt-6 text-end">
              <v-btn @click="selectedNuresDialog = true" class="secondary-btn">
                <span class="common-text">
                  {{ selectedNurses.length }} پرستار</span
                >
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tabs v-model="tab">
                <v-tab
                  class="primary--text common-text tab-title"
                  v-if="deviceType == 'desktop'"
                  >نمودار‌ها</v-tab
                >
                <!--<v-tab class="primary--text">جدول‌ها</v-tab>-->
              </v-tabs>
              <v-tabs-items v-model="tab">
                <!-- نمودار‌ها -->
                <v-tab-item v-if="deviceType == 'desktop'">
                  <v-container class="pa-3">
                    <div v-if="deviceType == 'desktop'">
                      <div>
                        <div class="mb-4 buttons-container">
                          <v-btn
                            v-if="chartStep != 1"
                            @click="fetchAllNursesData"
                            class="primary-btn ms-1 me-1"
                            dark
                            >گزارش عمومی</v-btn
                          >
                          <v-btn
                            v-if="chartStep > 2"
                            @click="fetchNurseYearlyData(selectedNurseName)"
                            class="primary-btn ms-1 me-1"
                            dark
                            >گزارش سالانه پرستار
                          </v-btn>
                          <v-btn
                            v-if="chartStep > 3"
                            @click="fetchNurseMonthlyData(selectedYear)"
                            class="primary-btn ms-1 me-1"
                            dark
                            >گزارش ماهانه پرستار
                          </v-btn>
                          <v-btn
                            @click="capture"
                            class="primary-btn ms-1 me-1"
                            dark
                            :loading="!showTitle"
                            :disabled="!loaded"
                          >
                            دریافت عکس نمودار‌ها</v-btn
                          >
                          <a
                            v-show="false"
                            class="btn btn-primary"
                            id="download"
                            >Download</a
                          >
                        </div>

                        <div id="capture">
                          <div>
                            <v-row>
                              <v-col>
                                <div v-if="showTitle">
                                  <p
                                    style="text-align: right; direction: rtl"
                                    v-if="chartStep == 1"
                                  >
                                    جهت مشاهده
                                    {{ faSelectedReportType }}
                                    هر پرستار روی پرستار مورد نظر کلیک کنید.
                                  </p>
                                  <p
                                    style="text-align: right; direction: rtl"
                                    v-if="chartStep == 2"
                                  >
                                    جهت مشاهده جزئیات روی یک ماه کلیک کنید.
                                  </p>
                                  <p
                                    style="text-align: right; direction: rtl"
                                    v-if="chartStep == 3"
                                  >
                                    جهت مشاهده جزئیات روی یک روز از ماه کلیک
                                    کنید.
                                  </p>
                                  <p
                                    style="text-align: right; direction: rtl"
                                    v-if="chartStep == 4"
                                  >
                                    جهت مشاهده گزارش هر شیفت روی آن کلیک کنید.
                                  </p>
                                </div>
                                <bar-chart
                                  v-if="loaded"
                                  :chartdata="dataBar"
                                  :options="optionsBar2"
                                >
                                </bar-chart>
                                <div v-else class="text-center pa-5">
                                  <v-progress-circular
                                    :size="50"
                                    :width="7"
                                    color="primary"
                                    indeterminate
                                  ></v-progress-circular>
                                  <h6 class="mt-8 primary--text text--darken-1">
                                    در حال بارگذاری...
                                  </h6>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
          <v-dialog v-model="selectedNuresDialog" width="600">
            <v-card class="modal-card">
              <v-container class="pa-5">
                <v-row class="mb-1">
                  <v-col class="text-right">
                    <span class="common-text primary--text pt-1"
                      >پرستار‌های انتخاب شده:</span
                    >
                  </v-col>
                  <v-col class="text-left">
                    <span
                      class="common-text"
                      @click="selectedNuresDialog = false"
                    >
                      <v-icon class="pointer">$Close</v-icon>
                    </span>
                  </v-col>
                </v-row>
                <div style="max-height: 400px; overflow-y: auto">
                  <div
                    v-for="(item, index) in selectedNurses"
                    :key="index"
                    class="my-4 c-grey d-flex flex-row"
                  >
                    <li class="pa-4 blue-grey--text text--darken-2 common-text">
                      {{ item }}
                    </li>
                  </div>
                </div>
              </v-container>
            </v-card>
          </v-dialog>
        </div>
      </div>

      <v-row v-if="!pageLoading" class="mt-8 w-100">
        <v-col
          class="text-right pe-0 ps-0"
          v-if="stepNumber < 5"
          md="4"
          sm="6"
          cols="12"
        >
          <v-btn
            class="purple-btn text-white no-underline btn-radius w-100 ms-3"
            elevation="0"
            height="43"
            @click="nextStep()"
            :disabled="computedNextButton"
            >گام بعدی</v-btn
          >
        </v-col>
        <v-spacer v-if="stepNumber < 5"></v-spacer>
        <v-col class="text-left pe-0 ps-0" md="4" sm="6" cols="12">
          <v-btn
            class="secondary-btn no-underline btn-radius w-100 ms-3"
            elevation="0"
            height="43"
            @click="previousStep()"
            v-if="stepNumber > 1"
            >گام قبلی</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <!--<div class="goToDown" @click="goDown()">
      <v-icon>mdi-chevron-down</v-icon>
    </div>-->
  </div>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import moment from "moment-jalaali";
import BarChart from "/src/components/charts/BarExample.vue";
import PieChart from "/src/components/charts/PieExample.vue";
import html2canvas from "html2canvas";
import YearModal from "@/components/YearModal";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    YearModal,
    BarChart,
    PieChart,
  },
  data() {
    return {
      filter: "",
      vLoading: false,
      nurses: [],
      isSelected: false,
      selectedNurses: [],
      selectedNursesIds: [],
      total: false,
      stepNumber: 1,
      timeType: "",
      selectYearDialog: false,
      selectTimeRangeDialog: false,
      selectedNuresDialog: false,
      selectDayModal: false,
      moment: moment,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      year: moment(new Date()).format("jYYYY"),
      selectedYear: "",
      currentYear: moment(new Date()).format("jYYYY"),
      range: {
        fromYear: parseInt(moment(new Date()).format("jYYYY")) - 1,
        fromMonth: moment(new Date()).format("jMM"),
        fromDay: moment(new Date()).format("jDD"),
        toYear: moment(new Date()).format("jYYYY"),
        toMonth: moment(new Date()).format("jMM"),
        toDay: moment(new Date()).format("jDD"),
      },
      monthsName: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      items: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      currentPage: 1,
      totalRows: "",
      perPage: 10,
      barCurrentPage: 1,
      barTotalRows: "",
      pieCurrentPage: 1,
      pieTotalRows: "",
      currentPage2: 1,
      projectCurrentPage: 1,
      totalRows2: "",
      tableBusy: false,
      items1: [],
      items2: [],
      selectedRange: {},
      fromNumberOfDay: null,
      toNumberOfDay: null,
      dateType: "",
      tab: null,
      barBusy: false,
      doughnutBusy: false,
      barLoaded: false,
      dataBar: {
        labels: [],
        datasets: [
          {
            label: "",
            backgroundColor: [],
            data: [],
          },
        ],
      },
      optionsBar: {
        title: {
          display: true,
          fontSize: 16,
          position: "top",
          fontFamily: "projectMainFont",
          text: "",
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "میلیون تومان",
                fontFamily: "projectMainFont",
              },
              ticks: {
                beginAtZero: true,
                fontFamily: "projectMainFont",
              },
              stacked: true,
            },
          ],
          xAxes: [
            {
              stacked: true,
              ticks: {
                fontFamily: "projectMainFont",
              },
            },
          ],
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            fontFamily: "projectMainFont",
          },
        },
        tooltips: {
          titleFontFamily: "projectMainFont",
          bodyFontFamily: "projectMainFont",
        },
        onClick: this.refreshChart,
      },
      optionsBar2: {
        title: {
          display: true,
          fontSize: 16,
          position: "top",
          fontFamily: "projectMainFont",
          text: "",
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "",
                fontFamily: "projectMainFont",
              },
              ticks: {
                beginAtZero: true,
                fontFamily: "projectMainFont",
              },
              stacked: false,
            },
          ],
          xAxes: [
            {
              stacked: false,
              ticks: {
                fontFamily: "projectMainFont",
              },
            },
          ],
        },
        legend: {
          display: true,
          position: "top",
          labels: {
            fontFamily: "projectMainFont",
          },
        },
        tooltips: {
          titleFontFamily: "projectMainFont",
          bodyFontFamily: "projectMainFont",
        },
        onClick: this.refreshChart,
      },

      dataPieOne: {
        labels: [],
        datasets: [
          {
            label: "درآمد (میلیون تومان)",
            backgroundColor: [
              "#9f9fd1", //درمانگاه عمومی
              "#3ea394", //درمانگاه عمومی
              "#9DCEE2", //دندانپرستاری
              "#f07167", //آزمایشگاه
              "#ee9b00", //خدمات در منزل
              "#ae2012", //خدمات لغو شده
            ],
            data: [],
          },
        ],
      },
      optionsPieOne: {
        title: {
          display: true,
          fontSize: 12,
          position: "top",
          fontFamily: "projectMainFont",
          text: "",
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          usePointStyle: true,
          position: "bottom",
          labels: {
            fontFamily: "projectMainFont",
          },
        },
        tooltips: {
          titleFontFamily: "projectMainFont",
          bodyFontFamily: "projectMainFont",
        },
      },
      deviceType: "",
      pageLoading: false,
      reactivity: true,
      allData: {},
      barTable: [],
      pieTable: [],
      showYearModal: false,
      showYearFromModal: false,
      showYearToModal: false,
      selectedReportType: "",
      faSelectedReportType: "",
      reportTypes: [
        {
          enName: "servicesNum",
          name: "تعداد کل خدمات",
        },
        {
          enName: "servicesPrice",
          name: "مبلغ کل خدمات",
        },
        // {
        //   enName: "servicesNumPerHour",
        //   name: "تعداد خدمات به ازای ساعت کارکرد",
        // },
        // {
        //   enName: "servicesPricePerHour",
        //   name: "مبلغ خدمات به ازای ساعت کارکرد",
        // },
      ],
      nurseExperties: [
        {
          enName: "nurse",
          name: "پرستار",
        },
        {
          enName: "dentistryNurse",
          name: "دستیار دندانپزشک",
        },
      ],
      selesctedNurseExperties: "",
      selesctedNurseExpertiesFa: "",
      dateFrom: moment(new Date()).subtract(30, "day").format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      chartStep: 1,
      showTitle: true,
      picName: "گزارش ۶ ماه گذشته",
      loaded: false,
      monthDic: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      selectedNurseId: "",
      firstChartLabels: [],
      firstChartIds: [],
      selectedNurseName: "",
      selectedMonth: "",
      selectedDay: "",
      daysDifference: "",
    };
  },
  methods: {
    selectReportType(reportType) {
      this.selectedReportType = reportType.enName;
      this.faSelectedReportType = reportType.name;
    },
    getItemYear(value) {
      this.year = value;
      this.selectedYear = this.year;
      this.timeType = "year";
    },
    getShowModal(value) {
      this.showYearModal = value;
    },
    persianMonthName(month) {
      let monthName;
      monthName = this.monthsName[Number(month) - 1];
      return monthName;
    },
    // getLogo(item) {
    //   if (this.university.filter((x) => x.id == item)) {
    //     return this.university.filter((x) => x.id == item)[0].url;
    //   } else return "";
    // },
    goDown() {
      var container = document.querySelector("html");
      var scrollHeight = container.scrollHeight;
      container.scrollTop = scrollHeight;
    },
    chooseNurse(item) {
      if (
        this.selectedNurses.length &&
        this.selectedNurses.includes(item.name)
      ) {
        let index;
        index = this.selectedNurses.indexOf(item.name);
        this.selectedNurses.splice(index, 1);
        this.selectedNursesIds.splice(index, 1);
      } else {
        this.selectedNurses.push(item.name);
        this.selectedNursesIds.push(item.id);
      }

      if (this.selectedNurses.length == this.nurses.length) {
        this.total = true;
      } else {
        this.total = false;
      }
    },
    selectAll() {
      this.nurses.forEach((x) => {
        if (!this.selectedNurses.includes(x.name)) {
          this.selectedNurses.push(x.name);
          this.selectedNursesIds.push(x.id);
        }
      });
      this.total = true;
      this.goDown();
    },
    removeAll() {
      this.selectedNurses = [];
      this.selectedNursesIds = [];
      this.total = false;
    },
    nextStep() {
      this.filter = "";
      ++this.stepNumber;
      if (this.stepNumber == 5) {
        this.tab = 0;
        this.fetchAllNursesData();
      }

      setTimeout(() => {
        document.querySelector("html").scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
    },
    previousStep() {
      this.filter = "";
      if (this.stepNumber == 5 && !this.selectedNurses.length) {
        this.stepNumber = 1;
      } else {
        --this.stepNumber;
      }

      setTimeout(() => {
        document.querySelector("html").scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
    },

    goToSelectRange() {
      this.selectTimeRangeDialog = true;
      this.getNumberOfDays("from");
      this.getNumberOfDays("to");
    },
    returnMonthNumber(month) {
      let monthNumber;
      monthNumber = this.monthsName.indexOf(month);
      if (monthNumber + 1 < 10) {
        return "0" + (monthNumber + 1);
      } else {
        return monthNumber + 1;
      }
    },
    //changeDate(type) {
    //  if (type == "year") {
    //    if (this.range.fromYear > this.range.toYear) {
    //      this.range.toYear = this.range.fromYear;
    //    }
    //    if (this.range.fromYear == this.range.toYear) {
    //      if (this.range.fromMonth > this.range.toMonth) {
    //        this.range.toMonth = this.range.fromMonth;
    //      }
    //    }
    //    if (
    //      this.range.fromYear == this.range.toYear &&
    //      this.range.fromMonth == this.range.toMonth
    //    ) {
    //      if (this.range.fromDay > this.range.toDay) {
    //        this.range.toDay = this.range.fromDay;
    //      }
    //    }
    //  }
    //  if (type == "month") {
    //    if (this.range.fromYear == this.range.toYear) {
    //      if (this.range.fromMonth > this.range.toMonth) {
    //        this.range.toMonth = this.range.fromMonth;
    //      }
    //      if (this.range.fromMonth == this.range.toMonth) {
    //        if (this.range.fromDay > this.range.toDay) {
    //          this.range.toDay = this.range.fromDay;
    //        }
    //      }
    //    }
    //  }
    //  if (type == "day") {
    //    if (this.range.fromYear == this.range.toYear) {
    //      if (this.range.fromMonth == this.range.toMonth) {
    //        if (this.range.fromDay > this.range.toDay) {
    //          this.range.toDay = this.range.fromDay;
    //        }
    //      }
    //    }
    //  }
    //},
    getNumberOfDays(type) {
      if (type == "from") {
        if (1 <= parseInt(this.range.fromMonth) < 7) {
          this.fromNumberOfDay = 31;
        }
        if (
          7 <= parseInt(this.range.fromMonth) &&
          parseInt(this.range.fromMonth) != 12
        ) {
          this.fromNumberOfDay = 30;
        }
        if (parseInt(this.range.fromMonth) == 12) {
          let isLead = moment(
            this.range.fromYear + "/12/30",
            "jYYYY/jMM/jDD"
          ).isValid();
          if (isLead) {
            this.fromNumberOfDay = 30;
          } else this.fromNumberOfDay = 29;
        }

        if (parseInt(this.range.fromDay) > parseInt(this.fromNumberOfDay)) {
          this.range.fromDay = this.fromNumberOfDay;
        }
      } else {
        if (1 <= parseInt(this.range.toMonth) < 7) {
          this.toNumberOfDay = 31;
        }
        if (
          7 <= parseInt(this.range.toMonth) &&
          parseInt(this.range.toMonth) != 12
        ) {
          this.toNumberOfDay = 30;
        }
        if (parseInt(this.range.toMonth) == 12) {
          let isLead = moment(
            this.range.toYear + "/12/30",
            "jYYYY/jMM/jDD"
          ).isValid();
          if (isLead) {
            this.toNumberOfDay = 30;
          } else this.toNumberOfDay = 29;
        }

        if (parseInt(this.range.toDay) > parseInt(this.toNumberOfDay)) {
          this.range.toDay = this.toNumberOfDay;
        }
      }
    },
    capture() {
      this.showTitle = false;
      setTimeout(() => {
        html2canvas(document.querySelector("#capture"))
          .then((canvas) => {
            var button = document.getElementById("download");
            button.setAttribute("href", canvas.toDataURL("image/png", 1));
            button.setAttribute("download", this.picName);
          })
          .then(() => {
            var button = document.getElementById("download");
            button.click();
            setTimeout(() => {
              this.showTitle = true;
            }, 500);
          });
      }, 500);
    },

    refreshChart(evt, item) {
      if (this.chartStep == 1) {
        this.selectedNurseName = item[0]["_model"].label;
        var nurseIndex = this.firstChartLabels.indexOf(this.selectedNurseName);
        this.selectedNurseId = this.firstChartIds[nurseIndex];
      } else {
        var label = item[0]["_model"].label;
      }
      switch (this.chartStep) {
        case 1:
          this.fetchNurseYearlyData();
          break;
        case 2:
          this.fetchNurseMonthlyData(label);
          break;
        case 3:
          this.fetchNurseDayData(label);
          break;
        case 4:
          this.fetchNurseShiftData(label);
          break;
        case 5:
          this.gotoShift(item[0]["_index"]);
          break;
        default:
          break;
      }
    },
    // get all nurses
    getAllNurses() {
      this.pageLoading = true;
      this.$http
        .post(
          `${this.baseUrl}/clinic/hr/employees/byRole`,
          {
            role: this.selesctedNurseExperties,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.nurses = res.data;
            this.pageLoading = false;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.vLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    // get data for charts about all nurses
    fetchAllNursesData() {
      this.showTitle = false;
      let reportTimeType = "";
      if (this.timeType == "all") {
        reportTimeType = `گزارش تجمیعی "${this.faSelectedReportType}"${
          this.faSelectedReportType == "مبلغ کل خدمات" ? ` به میلیون تومان` : ""
        }`;
      } else if (this.timeType == "year") {
        reportTimeType = `گزارش "${this.faSelectedReportType}" سال ${
          this.selectedYear
        }${
          this.faSelectedReportType == "مبلغ کل خدمات" ? ` به میلیون تومان` : ""
        }`;
      } else if (this.timeType == "time") {
        reportTimeType = `"${this.faSelectedReportType}" از تاریخ ${
          this.dateFrom
        } تا تاریخ ${this.dateTo}${
          this.faSelectedReportType == "مبلغ کل خدمات" ? ` به میلیون تومان` : ""
        }`;
      }
      this.picName = `گزارش ${this.selesctedNurseExpertiesFa} (${reportTimeType})`;
      this.loaded = false;
      this.chartStep = 1;
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/reports/personnels",
          {
            userIds: this.selectedNursesIds,
            userRole: this.selesctedNurseExperties,
            reportType: this.selectedReportType,
            year: this.timeType == "year" ? this.year : "",
            month: this.timeType == "time" ? this.dateFrom : "",
            day: this.timeType == "time" ? this.dateTo : "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.dataBar = res.data.results;
            this.firstChartLabels = res.data.results.labels;
            this.firstChartIds = res.data.userIds;
            this.optionsBar2.title.text = reportTimeType;
            this.showTitle = true;
            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    // click on one doctor to show its yearly data
    fetchNurseYearlyData() {
      this.showTitle = false;
      this.picName = ` گزارش سالانه ${this.faSelectedReportType} ${this.selectedNurseName}`;
      this.loaded = false;
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/reports/personnelByYears",
          {
            reportType: this.selectedReportType,
            userId: this.selectedNurseId,
            userRole: this.selesctedNurseExperties,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.dataBar = res.data.results;
            this.optionsBar2.title.text = `نمودار سالانه "${
              this.faSelectedReportType
            }" ${this.selectedNurseName}${
              this.faSelectedReportType == "مبلغ کل خدمات"
                ? ` به میلیون تومان`
                : ""
            }`;
            this.chartStep = 2;

            this.showTitle = true;
            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    // click on a years to show its monthly data
    fetchNurseMonthlyData(date) {
      this.showTitle = false;
      this.loaded = false;
      this.picName = `گزارش ماهانه سال ${date} ${this.selectedNurseName}`;
      this.selectedYear = date;
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/reports/personnelPerMonths",
          {
            reportType: this.selectedReportType,
            userId: this.selectedNurseId,
            userRole: this.selesctedNurseExperties,
            year: date,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.dataBar = res.data.results;
            this.optionsBar2.title.text = `گزارش ماهانه "${
              this.faSelectedReportType
            }" سال ${date} ${this.selectedNurseName}${
              this.faSelectedReportType == "مبلغ کل خدمات"
                ? ` به میلیون تومان`
                : ""
            }`;

            this.chartStep = 3;
            this.showTitle = true;
            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    // click on a month to show its daily data
    fetchNurseDayData(date) {
      this.showTitle = false;
      this.loaded = false;
      this.globalMonth = this.returnMonthNumber(date);
      this.selectedMonth = this.selectedYear + "/" + this.globalMonth;
      this.picName = `گزارش ${this.faSelectedReportType} ${this.selectedNurseName} در ${this.selectedMonth}`;
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/reports/personnelPerDays",
          {
            reportType: this.selectedReportType,
            userId: this.selectedNurseId,
            userRole: this.selesctedNurseExperties,
            year: this.selectedYear,
            month: this.globalMonth,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.dataBar = res.data.results;
            this.optionsBar2.title.text = `گزارش "${
              this.faSelectedReportType
            }" ${this.selectedNurseName} در ${this.selectedMonth}${
              this.faSelectedReportType == "مبلغ کل خدمات"
                ? ` به میلیون تومان`
                : ""
            }`;

            this.chartStep = 4;
            this.showTitle = true;
            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    // click on a day to show its shift data
    fetchNurseShiftData(date) {
      this.showTitle = false;
      this.loaded = false;
      this.selectedDay = this.selectedMonth + "/" + date;
      this.picName = `گزارش شیفت‌های تاریخ ${this.selectedDay} ${this.selectedNurseName}`;
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/reports/personnelPerShifts",
          {
            reportType: this.selectedReportType,
            userId: this.selectedNurseId,
            userRole: this.selesctedNurseExperties,
            year: this.selectedYear,
            month: this.globalMonth,
            day: date,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.dataBar = res.data.results;
            this.shiftIds = res.data.shiftIds;
            this.optionsBar2.title.text = `گزارش "${
              this.faSelectedReportType
            }" شیفت‌های تاریخ ${this.selectedDay} ${this.selectedNurseName}${
              this.faSelectedReportType == "مبلغ کل خدمات"
                ? ` به میلیون تومان`
                : ""
            }`;

            this.chartStep = 5;
            this.showTitle = true;
            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    gotoShift(index) {
      //this.$router.push('/admin/shiftReport/'+this.dataBar.datasets[0].shiftIds[index])
      let link = "/admin/shiftReport/" + this.shiftIds[index];
      window.open(link, "_blank");
    },
  },
  computed: {
    computedNurses() {
      if (this.filter) {
        let filtered;
        filtered = this.nurses.filter(
          (x) =>
            x.name.includes(this.filter) || x.expertise.includes(this.filter)
        );
        return filtered;
      } else {
        return this.nurses;
      }
    },
    computedNextButton() {
      if (this.stepNumber == 1) {
        if (!this.selectedReportType) {
          return true;
        } else return false;
      }
      if (this.stepNumber == 2) {
        if (!this.selesctedNurseExperties) {
          return true;
        } else return false;
      }
      if (this.stepNumber == 3) {
        if (!this.selectedNurses.length) {
          return true;
        } else return false;
      }
      if (this.stepNumber == 4) {
        if (!this.timeType) {
          return true;
        } else return false;
      }
    },
    computedCharts() {
      if (!this.allData.barChartHasData && !this.allData.pieChartHasData) {
        return true;
      } else return false;
    },
    computedNumOfDays() {
      let firstDate = moment(this.dateFrom, "jYYYY/jMM/jDD-HH:mm").format("X");
      let secondDate = moment(this.dateTo, "jYYYY/jMM/jDD-HH:mm").format("X");
      const timeDifference = secondDate - firstDate;
      this.daysDifference = Math.floor(timeDifference / 86400);
      if (this.daysDifference > 0) {
        return this.daysDifference + 1;
      } else {
        return "لطفا تاریخ را به درستی انتخاب کنید.";
      }
    },
  },
  mounted() {
    if (window.screen.width < 500) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 500) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    //this.gettingPic = [false, false, false];
  },
};
</script>
<style lang="scss">
.modal-card {
  border-radius: 10px !important;
  .edit-icon {
    path {
      stroke: #068d9d !important;
    }
    transform: translateY(-2px);
  }
  .v-card__title {
    border-radius: 10px 10px 0 0;
  }
  .day-box {
    width: 37px;
    height: 37px;
    text-align: center;
    border-radius: 50%;
    padding-top: 8px;
    color: #656a81;
    &:hover {
      background-color: #eeeeee;
    }
    &.selected {
      background-color: #e6e6e6;
      color: #068d9d;
      font-weight: bold;
    }
  }
  .small-logoBox {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
button {
  outline: none !important;
}
.completed {
  &.v-divider {
    height: 8px !important;
    max-height: 8px !important;
    border-radius: 5px !important;
    background-color: red !important;
    border: none !important;
  }
}
</style>
